import React from 'react';
import {MdSettings} from 'react-icons/md';
import Header from '../../GobelElements/Header';
import { Button} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import logo from '../../assets/logo-3.png';
import API from '../../Api';
import { Rootcontainer, RootDiv, RootTitle, RootBal, RootRight ,RootLeft,RootValue,FooterUl,FooterLi} from './styled'
import {
    MdPowerSettingsNew
} from 'react-icons/md';
import GeneralValues from '../../GobelElements/Values';

export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
       isloggedOut:false,
       lcoID: localStorage.getItem("userID"),
       lcoPassword: localStorage.getItem("userPassword"),
       domainName:localStorage.getItem("domain"),
       dashboardData:{},
       errorMessage:"",
       spinnerVisible:false,
       lcoBalance:"",
       lcoName:""

        };
        console.log("username",localStorage.getItem("userID"));
        // this.getDashboardDetails();
        this.getLCO_Details();
    }
    getLCO_Details() {
        const {lcoID,lcoPassword,domainName}=this.state;
        debugger;
        API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({ lcoName: responsejson.lconame, lcoBalance: responsejson.balance })
              
            })
    }

    logOutFunc(){
        if(this.state.isloggedOut){
              return <Redirect to="/login"/>
        }else{
          return null;
        }
       }
       logOut(){
         
         localStorage.removeItem("userID");
         const username = localStorage.getItem("userID");
         if(!username){
            this.setState({isloggedOut:true});
         }
       }
    render(){
        return(
            <Rootcontainer>
        <div className="header-div">
                    <h3>Settings</h3>
                    <img 
                    onClick={()=>{this.props.moveToDashBoard()}}
                    src={GeneralValues} className="logo-img" />
                    <MdPowerSettingsNew onClick={()=>this.logOut()} value="Logout" className="fontSize"/>
                </div>
                <RootDiv>
                <RootLeft>
                <RootTitle>
                                        Name :
                                    </RootTitle>
                
                                    <RootValue >
                                        {this.state.lcoName}
                                    </RootValue>
                </RootLeft>
                                   <RootRight>
                                    <RootTitle>
                                        Balance :
                </RootTitle>
                
                                    <RootValue>
                                     {'\u20B9'} {this.state.lcoBalance}
                </RootValue>
                
                </RootRight>
                                </RootDiv>
   
                                <Button variant="success" size="lg" className="mt-3" style={{fontWeight:600,width:"97%",marginTop:0,marginLeft:23}}><MdPowerSettingsNew /> Sign Out</Button>
                                {this.logOutFunc()}
                                </Rootcontainer>
           
      
        )
    }
}